import React, { useState, useEffect } from 'react';
import axios from 'axios';
import projects from './projects.json';
import { TextField, Grid, Container, Card, CardContent, Typography, Button } from '@mui/material';
import './project.css';
const Projects = () => {

  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [projectsPerPage] = useState(3);
  const [selectedTags, setSelectedTags] = useState([]);

  const handleTagClick = (tag) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter((t) => t !== tag));
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
    setCurrentPage(1);
  };
  const renderTags = () => {
    const allTags = Array.from(new Set(projects.flatMap((project) => project.tags)));
    
    return allTags.map((tag) => (
      <button
        key={tag}
        onClick={() => handleTagClick(tag)}
        className={`tag-button ${selectedTags.includes(tag) ? 'tag-button-selected' : ''}`}
      >
        {tag}
      </button>
    ));
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset to the first page when performing a new search
  };

  const filteredProjects = projects.filter((project) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const lowerCaseTags = selectedTags.map((tag) => tag.toLowerCase());
  
    // Check if the project name or description includes the search term
    const matchesSearch = (
      project.name.toLowerCase().includes(lowerCaseSearchTerm) ||
      project.technologies.some((technology) => technology.toLowerCase().includes(lowerCaseSearchTerm))
    );
  
    // Check if the project contains at least one selected tag
    const matchesTags = selectedTags.length === 0 || project.tags.some((tag) => lowerCaseTags.includes(tag.toLowerCase()));
  
    return matchesSearch && matchesTags;
  });
  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const paginatedProjects = filteredProjects.slice(indexOfFirstProject, indexOfLastProject);

  const renderProjects = paginatedProjects.map((project) => (
    <Grid item xs={12} key={project.id}>
      <Card>
        <CardContent>
          <Typography variant="h5" component="div">
            {project.name}
          </Typography>
          <Typography color="text.secondary">{project.description}</Typography>
          <Typography color="text.secondary">
            <strong>Technologies:</strong> {project.technologies.join(', ')}
          </Typography>
          <Typography color="text.secondary">
            <strong>URL:</strong>{' '}
            <a href={project.url} target="_blank" rel="noopener noreferrer">
              {project.url}
            </a>
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  ));

  const totalPages = Math.ceil(filteredProjects.length / projectsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const renderPaginationButtons = () => {
    const pageButtons = [];
    for (let page = 1; page <= totalPages; page++) {
      pageButtons.push(
        <Button
          key={page}
          variant={page === currentPage ? 'contained' : 'outlined'}
          onClick={() => handlePageChange(page)}
        >
          {page}
        </Button>
      );
    }
    return pageButtons;
  };

  return(<div className='mt-5'>
  <h1 className="text-4xl font-bold">Projects</h1>
  <p className="text-2xl text-gray-400 mb-5">Upgrading and expressing my skillset</p>
  <Container maxWidth="sm">
    <Grid container spacing={2} justifyContent="center" mt={4}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Search"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearch}
        />
      </Grid>
      <Grid item xs={12}>
        {/* Render tag filter buttons */}
        <div className="tag-container space-x-2">{renderTags()}</div>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {renderProjects}
        </Grid>
      </Grid>
      <Grid item xs={12} mt={2}>
        <Grid container justifyContent="center" className='space-x-2'>
          {renderPaginationButtons()}
        </Grid>
      </Grid>
    </Grid>
  </Container>
</div>);
}

export default Projects;
