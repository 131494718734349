import React from "react";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import resume from '../assets/SiddhantShah.pdf';
export default function DownloadResume() {
    return (
        <div className='flex justify-center items-center'>
            <a href={resume} rel="noreferrer" target="_blank">
                <button type='button' className='border-2 border-gray-700 hover:bg-slate-300 text-gray-900 hover:text-cyan-600 hover:border-cyan-600 font-bold py-2 px-4 rounded'>
                    <FileCopyIcon/> Download CV
                </button>
            </a>
        </div>
    );
}