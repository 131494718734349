import Timeline from '@mui/lab/Timeline';
import TimelineItemProps from './TimelineItemProps';

const Education = () => {
    return(
        <Timeline position="alternate">
      <TimelineItemProps company='Northeastern University' duration='Sep 2021 - May 2023' title='Master of Science in Computer Science' place='Boston, MA' type='Education'/>
      <TimelineItemProps company = 'Pune University' duration='Aug 2016 - May 2020' title='Bachelor of Engineering in Computer Engineering' type='Education'/>
      <TimelineItemProps company = "Fergusson College" duration='Jun 2014 - May 2016' title='Higher Secondary Education' type='Education'/>
      <TimelineItemProps company = "The Bishop's School" duration='Jun 2002 - May 2014' title='Secondary Education' type='Education'/>
    </Timeline>
    );
}
export default Education;