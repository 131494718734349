import SkillCardDisplay from "./SkillCardDisplay/SkillCardDisplay"
const SkillList = ({skills}) => {
    return(
    <>
        <span className="text-4xl font-bold text-center md:text-left">Core Competencies</span>
        <p className="text-2xl text-gray-400">This is my toolset</p>
            <div className="flex space-x-8 md:space-y-8 md:flex-row justify-center flex-wrap " >
                <div className="p-4 m-2 ">
                    <SkillCardDisplay skills={skills}/>
                </div>
        </div>
        </>
        )

}

export default SkillList;