import * as React from 'react';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import ResponsiveDialog from '../Modal/ResponsiveDialog';
import TimelineDot from '@mui/lab/TimelineDot';
const TimelineItemProps = ({company, duration, title = '', type = null, place = null, hover}) => {
  const HoverSet = () => {
    // console.log('inside hover for ', title, 'with value ', hover);
      if(hover) {
        return (type && 
          <span className='block opacity-0 group-hover:opacity-100'>{title} </span>)
      }
      else {
        // console.log('returning title', title);
       return( type && 
          <span className='block '>{title} </span> )
      }
  }
    return( 
    <TimelineItem className='group'>
  
      
      <TimelineSeparator >
        <TimelineConnector />
        <TimelineDot sx={{backgroundColor:'#383838'}}>
          {/* <WorkOutline/> */}
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent sx={{ py: '12px', px: 2 }}>
        {/* 
        This part has been changed with ResponsiveDialog.js

        <Typography variant="h6" component="span"> */}
        {/* <span className='text-xl font-bold block'>
          {company}
          </span> */}
        {/* </Typography> */}
        {/* <span className='block'>{duration}</span>
        <span className='text-sm'>{place}</span> */}
        <ResponsiveDialog company={company} duration={duration} place={place} title={title} type={type}/>
        <HoverSet/>
      </TimelineContent>
    </TimelineItem>);
  }
  export default TimelineItemProps;