import React from 'react';

const SkillCardDisplay = ({skills}) => {
    return (
<div className='grid grid-cols-1 md:grid-cols-2 gap-6 xl:gap-8'>
  {Object.values(
    skills.reduce((acc, skill) => {
      if (!acc[skill.name]) {
        acc[skill.name] = { ...skill };
      } else {
        acc[skill.name].framework += `, ${skill.framework}`;
      }
      return acc;
    }, {})
  ).map((skill, index) => (
    <div className='p-6 bg-gray border-2 border-black shadow-lg rounded-lg'>
      <div className='flex items-center'>
        {skill.img && <img src={skill.img} alt='' className='mr-4 h-8 w-8' />}
        <span className='font-bold text-lg text-gray-900 dark:text-gray-100'>{skill.name}</span>
      </div>
      <div className='grid grid-cols-2 gap-1 mt-3'>
        {skill.framework.split(', ').map((framework, frameworkIndex) => (
          <div
            key={frameworkIndex}
            className='px-3 py-1 rounded-md cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-700'
          >
              {framework.img && <img src={framework.img} alt='' className='mr-2 h-6 w-6' /> }
            <span className='text-base text-gray-700 dark:text-gray-300'>{framework}</span>
          </div>
        ))}
      </div>
    </div>
  ))}
</div>


    );
};

export default SkillCardDisplay;
