import React from "react";

//This file and construct is temporary. It will be replaced by a database call.

const FetchData = (type, company, title) => {
    if (!type || !company) {
        return { Points: [type, company, title] };
      }
    
    const data = {

        "Experience": {
            "TERVA Corporation, MA": {
                "Software Engineer II": [
                    "Developed a scalable and secure RESTful API using FastAPI to handle data storage and augmentation",
                    "Designed and implemented a microservice architecture using Azure functions, resulting in a 40% reduction in infrastructure costs",
                    "Leading the new software department and initiative to develop a web application using Next.js, React, Redux, and Material-UI, resulting in a 30% increase in user productivity"
                ]
            },
            "Northeastern University": {
                "Digital Experience Assistant": [
                    "Worked on a team of 4 to develop a web application using React.js, Node.js, and MongoDB",
                    "Developed a REST API to handle user authentication and data storage",
                    "Implemented a user interface to allow users to create, edit, and delete data",
                    "Deployed the application to Azure using Docker and Azure App Services"
                ],
                "Graduate Teaching Assistant": [
                    "Assisted faculty members in delivering lectures, preparing materials, and managing classroom activities for undergraduate computer science courses.",
                    "Conducted weekly discussion sessions to reinforce course concepts, clarify doubts, and engage students in interactive learning.",
                    "Provided one-on-one tutoring and support to students struggling with course material, resulting in improved academic performance for several students."
                ],
                "Graduate Research Assistant": [
                    "Center for Translational Nuero Imaging Lab",
                    "Designed data warehouse on AWS S3 & business intelligence platform using Flask that streamlined data collection, integration & reporting resulting in a 25% reduction in data processing time",
                    "Conducted data preprocessing, cleaning, and quality control of neuroimaging data from 20+ experiments using MRIQC and custom Python scripts, achieving a data quality score of 90% or higher"
                ]
            },
            "SS&C Intralinks": {
                "Software Engineer Co-op":[
                    "Improved website loading time by utilizing code splitting techniques, resulting in a significant 30% reduction in JavaScript bundle size and enhanced front-end performance",
                    "Performed unit testing on 30+ components, uncovered 7 critical bugs on StoryBook",
                    "Implemented industry-standard practices like Component Story Formats (CSF) and achieved a 90% test pass rate through CI/CD practices"
                ]
            },
            "Globant":{
                "Software & Data Engineering, ServiceNow": [
                    "Evaluated leadership requirements of a Fortune 100 company across 5 regions and developed a unified ServiceNow platform, reducing redundant reports by approximately 73%",
                    "Increased month-over-month report viewership by 20% through standardizing local instances to a global standard and resolving discrepancies",
                    "Leveraged ServiceNow API to transfer data to Microsoft SQL Servers and develop interactive reports with Power BI, saving over 500 man-hours on report creation and circulation"
                ]
            },
            "Visava Labs":{
                "Machine Learning, Research": [
                    "Secured 80% of first-round funding by pitching a cutting-edge product at the intersection of Technology & Architecture to address accessibility issues at scale in R&D department",
                    "Integrated expert-based system with Android app reducing delivery time to 20 seconds boosting engagement",
                    "Utilized Generative Adversarial Networks (Big GAN) to create over 1000 possible floor plans based on given input constraints though the ML Ops pipeline on Google Cloud"
                ]
            },
            "Freelance":{
                "Web Development, Business Analytics": [
                    "Created an interactive adaptive website for Suyog Life Care using responsive design principles, resulting in a 90% increase in viewership across all devices",
                    "Implemented SEO best practices, including keyword research and optimization in collaboration with the marketing team, leading to a 20% increase in organic traffic",
                    "Executed promotional campaigns for social media profiles and email marketing using SendGrid's RESTful APIs, resulting in a 30% increase in customer acquisition and retention"
                ]
            },
            "IoTIoT.in":{
                "Computer vision, AI on edge": [
                    "Used in-house arm-based Shunya OS with custom kernels increasing efficiency AIoT solution saving 3% power",
                    "Analyzed different CNN models to minimize number of facial landmarks to 18 data points, saving 10000 faces in 15kbs, saving $300+ in storage costs",
                    "Explored and experimented with FaceNet, a state-of-the-art deep learning model for face recognition and triplet loss, to develop robust and accurate face recognition systems."
                ]
            },
            "Pune University": {
                "Undergraduate Research Assistant": [
                    "",
                ]
            }
        },
        "Education": {
            "Northeastern University": {
                "Master of Science in Computer Science": [
                    "Studied Master of Science in Computer Science",
                    "Focus on Artificial Intelligence and Machine Learning",
                ]
            },
            "Pune University": {
                "Bachelor of Engineering in Computer Engineering": [
                    "Studied Bachelor of Engineering in Computer Engineering",
                    "Focus on Artificial Intelligence and Machine Learning applications on Software Defined Networks",
                ]
            }
        }
    }
    
    if(data[type] && data[type][company] && data[type][company][title]) {
        return { Points: data[type][company][title] };
    }
    return { Points: [""] };
}
export default FetchData;