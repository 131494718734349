import * as React from 'react';
import Timeline from '@mui/lab/Timeline';

import TimelineItemProps from './TimelineItemProps';

export default function CustomizedTimeline() {
  
  return (
    <Timeline position="alternate">
      <TimelineItemProps company='TERVA Corporation, MA' duration='Aug 2023 - Present' title='Software Engineer II' type='Experience'/>
      <TimelineItemProps company= "Keelworks" duration = 'July 2023 - Seasonal' title='Machine Learning Engineer, Full Stack Developer' type='Experience'/>
      <TimelineItemProps  company='Northeastern University' duration='Sep 2022 - May 2023' title='Digital Experience Assistant' type='Experience'/>
      <TimelineItemProps company='SS&amp;C Intralinks' duration='Sep 2022 - Dec 2022' title='Software Engineer Co-op' place='Waltham, MA' type='Experience'/>
      <TimelineItemProps  company='Northeastern University' duration='May 2022 - Jul 2022' title='Graduate Research Assistant' type='Experience'/>
      <TimelineItemProps  company='Northeastern University' duration='Sep 2021 - Sep 2022' title='Graduate Teaching Assistant' type='Experience'/>
      <TimelineItemProps company = 'Globant' duration='Dec 2020 - Aug 2021' title='Software &amp; Data Engineering, ServiceNow'type='Experience' />
      <TimelineItemProps company = 'Freelance' duration='Jun 2020 - Sep 2021' title='Web Development, Business Analytics'type='Experience' />
      <TimelineItemProps company = 'Visava Labs' duration='Jun 2018 - Jun 2020' title='Machine Learning, Research'type='Experience' />
      <TimelineItemProps company = 'IoTIoT.in' duration= 'Feb 2018 - Jun 2018' title='Computer vision, AI on edge'type='Experience' />
      <TimelineItemProps company = 'Pune University' duration='Sep 2017 - May 2019' title='Undergraduate Research Assistant'type='Experience' />
    </Timeline>
  );
}