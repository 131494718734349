import React, { useState } from 'react';
// import './timeline.css';
import { Button, ButtonGroup } from '@mui/material';
import { WorkOutline, School } from '@mui/icons-material';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import Education from './Education';
import Title from '../Title';
import Experience from './Experience';
import WorkIcon from '@mui/icons-material/Work';


const Timeline = () => {
  const [activeButton, setActiveButton] = useState('experience');

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    // You can perform additional logic here based on the button clicked
  };
  
  return (
    <div className="justify-center mt-10">
      <h1 className="text-4xl font-bold">Journey</h1>
      <p className="text-2xl text-gray-400 mb-5">An illustration of my work and education through the years</p>
      <ButtonGroup >
        <Button 
          sx={{
            py: 2, // Adjust this value to make the button larger or smaller
            backgroundColor: activeButton === 'experience'  ? '#ADCAED' : '#CBD5E1',
            color: 'black',
            border:'none',
            borderRadius: '9px', // Add this line for rounded corners
            '&:hover': {
              border: '#ADCAED', 
              backgroundColor: 'none', // Remove the background on hover
            },
          }}
          onClick={() => handleButtonClick('experience')}
          startIcon={activeButton === 'experience' ?<WorkIcon/>: <WorkOutline />}
        >
          Experience
        </Button>
        <Button
          sx={{
            py: 2, // Adjust this value to make the button larger or smaller
            backgroundColor: activeButton === 'education' ? '#ADCAED' : '#CBD5E1' ,
            color: 'black',
            border:'none',
            borderRadius: '9px', // Add this line for rounded corners
            '&:hover': {
              border: '#ADCAED', 
              backgroundColor: 'none', // Remove the background on hover
            },
          }}
          onClick={() => handleButtonClick('education')}
          startIcon={activeButton === 'education' ?<School/>: <SchoolOutlinedIcon />}
        >
          Education
        </Button>
      </ButtonGroup> 
      {/* Render your timeline based on the active button */}
      <div className="justify-center mt-10">
        {activeButton === 'experience' && (
          // Render experience timeline component
          <div className="">
            {/* Experience timeline content */}
            <Experience />
          </div>
        )}

        {activeButton === 'education' && (
          // Render education timeline component
          <div className="">
            {/* Education timeline content */}
            <Education />
          </div>
        )}
      </div>
    </div>
  );
};
export default Timeline;