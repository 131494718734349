import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SkillCard from "./SkillCard.js"
import Frontend from "./skillset/SkillCardDisplay/SkillCardDisplay.js"
import SkillList from "./skillset/SkillList.jsx" 
// import Backend from "./skillset/Backend/Backend.js"

import PythonLogo from '../../assets/skills/python.svg';
import PyTorch from '../../assets/skills/PyTorch.svg'
import javascript from '../../assets/skills/javascript.svg'
import reactIcon from '../../assets/skills/react.svg'
import tailwind from '../../assets/skills/tailwind.svg'
import git from "../../assets/skills/git.svg"
import OpenCV from '../../assets/skills/OpenCV.svg'

export default function Skills() {
  const skills = [
    { name: 'Web Development', framework: 'Bootstrap'},//, img: 'path/to/img' },
    { name: 'Web Development', framework: 'Html'},//, img: 'path/to/img' },
    { name: 'Web Development', framework: 'CSS'},//, img: 'path/to/img' },
    { name: 'Web Development', framework: 'React'},//, img: 'path/to/img' },
    { name: 'Web Development', framework: 'Node.js'},// img: 'path/to/img' },
    { name: 'Web Development', framework: 'Angular'},// img: 'path/to/img' },
    {  name: 'Web Development', framework: 'Django'},// img: 'path/to/img' },
    { name: 'Web Development', framework: 'Tailwind CSS'},// img: 'path/to/img' },
    { name: 'Web Development', framework: 'Material UI'},// img: 'path/to/img' },
    { name: 'Web Development', framework: 'Sass'},// img: 'path/to/img' },
    { name: 'Web Development', framework: 'Next.js'},// img: 'path/to/img' },
    { name: 'Web Development', framework: 'IBM Carbon Components'},// img: 'path/to/img' },
    { name: 'Machine Learning', framework: 'PyTorch'},// img: 'path/to/img' },
    { name: 'Machine Learning', framework: 'OpenCV'},// img: 'path/to/img' },
    { name: 'Machine Learning', framework: 'Scikit-Learn'},// img: 'path/to/img' },
    { name: 'Machine Learning', framework: 'TensorFlow'},// img: 'path/to/img' },
    { name: 'Machine Learning', framework: 'Keras'},// img: 'path/to/img' },
    { name: 'Machine Learning', framework: 'Pandas'},// img: 'path/to/img' },
    { name: 'Machine Learning', framework: 'Numpy'},// img: 'path/to/img' },
    { name: 'Machine Learning', framework: 'Matplotlib'},// img: 'path/to/img' },
    { name: 'Machine Learning', framework: 'Seaborn'},// img: 'path/to/img' },
    { name: 'Machine Learning', framework: 'Plotly'},// img: 'path/to/img' },
    { name: 'Machine Learning', framework: 'NLTK'},// img: 'path/to/img' },
    { name: 'Machine Learning', framework: 'Spacy'},// img: 'path/to/img' },
    { name: 'Machine Learning', framework: 'Gensim'},// img: 'path/to/img' },
    { name: 'Machine Learning', framework: 'XGBoost'},// img: 'path/to/img' },


  ];
  const settings = {
    centerMode: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 1200,
    arrows: false,
    centerPadding: '60px',
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 3
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1
        }
      }
    ]
  };

  return (
    <div id="skills" className="mt-4 py-0.5 mb-2">
      <SkillList skills = {skills} />
      <h1 className="text-4xl font-bold">Skills</h1>
      <p className="text-2xl text-gray-400">Here are some of my skills</p>

      <div className="my-4">
        <Slider {...settings}>
          <SkillCard name="Python" experience="6 years" img={PythonLogo} />
          <SkillCard name="PyTorch" experience="3 year" img={PyTorch} />
          <SkillCard name="OpenCV" experience="3 year" img={OpenCV} />
          <SkillCard name="JavaScript" experience="5 year" img={javascript} />
          <SkillCard name="react" experience="3 year" img={reactIcon} />
          <SkillCard name="TailWind" img={tailwind} />
          <SkillCard name="Git" experience="6 year" img={git} />
        </Slider>
        
      </div>
      
    </div>
  );
}
