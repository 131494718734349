import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import FetchData from './FetchData';

export default function ResponsiveDialog({company,duration,title='',place = '', type = ''}) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  
  const data = FetchData(type, company, title);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const DataPoints = (data) => {
    // console.log(DataPoints + 'is data points');
    if(data.Points[0].length > 0){
      return(
    <ul className='list-disc'>
              {data.Points.map((point, index) => (<li key = {index} className=''>{point}</li>))}
            </ul>
      );
    }
  }

  return (

    <div >
      <div  onClick={handleClickOpen} className="cursor-pointer">
        <span className='text-xl font-bold block text-slate-700  hover:underline hover:text-cyan-700'>
            {company}
            </span>

          <span className='text-black hover:text-cyan-900 hover:underline'>{duration}</span>
          <span className='text-black text-sm block hover:text-cyan-900'>{place}</span>

      </div>
      
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <span className='text-3xl font-bold text-cyan-600'>{company}</span><br/>
            <span className='text-black'>{duration}</span><br/>
            <span className='text-black text-sm'>{place}</span><br/>
            {DataPoints(data)}
          </DialogContentText>
          <DialogActions>
            
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}